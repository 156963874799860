import { Component, OnDestroy, OnInit, Signal, ViewChild, viewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionBarGroup } from '@common/components/action-bar/action-bar.interface';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { BaseInputComponent } from '@common/components/input/base-input/base-input.component';
import { CanDeactivateReturn, DeactivableComponent } from '@common/guards/can-deactivate.guard';
import { CommandParams } from '@common/interfaces/command-params.interface';
import { Tab } from '@common/interfaces/tab.interface';
import { User } from '@common/models/user';
import { ValueOf } from '@common/models/util.interface';
import { ViewMode } from '@common/models/view-mode';
import { CommonService } from '@common/services/common.service';
import { QueryService } from '@common/services/query.service';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { tapSuccessResult } from '@ngneat/query';
import { TranslateService } from '@ngx-translate/core';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { DialogService } from '@services/dialog.service';
import _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';

interface BaseModel {
    id: number | string;
    statusId?: string;
}

@Component({ template: '' })
export abstract class BaseViewComponent<
        TQuery = any,
        TViewModel extends BaseModel = any,
        TSaveModel extends BaseModel = any
    >
    implements OnInit, OnDestroy, DeactivableComponent
{
    destroy$ = new Subject<boolean>();

    user: User;

    readonly viewMode: boolean;
    readonly createMode: boolean;
    readonly editMode: boolean;
    actionBar: ActionBarGroup[];
    public selection = [];
    public hasUnsavedChanges = false;

    protected queryService: QueryService;
    protected translateService: TranslateService;
    protected toastrNotificationService: ToastrNotificationService;
    protected activatedRoute: ActivatedRoute;
    protected dialogService: DialogService;
    protected router: Router;

    public mode: ValueOf<typeof ViewMode>;
    protected id: number | string;
    model: Partial<TViewModel & TSaveModel> = {};
    query$: any;
    editPermission: string;
    createPermission: string;

    abstract entityName: string;
    arrayProperties: string[] = []; // Initialize empty array for array properties in create mode
    parentRoute: string;
    title: string;
    initialModel = {};

    tabs: Tab<any>[];
    activeTab = 0;

    /**
     * Extra parameters to be passed alongside the ID of the entity
     */
    loadExtraParams = {};
    /**
     * @deprecated
     * Here until pages are refactored to use new NavigationService. This will be removed in the future.
     */
    breadcrumbs = null;
    /**
     * @deprecated
     * Here until pages are refactored to use new NavigationService. This will be removed in the future.
     */
    currentNavItem = null;

    @ViewChild(TabStripComponent) tabstrip: TabStripComponent;
    appControls: Signal<readonly AppControlComponent[]> = viewChildren(AppControlComponent);
    inputs: Signal<readonly BaseInputComponent<any>[]> = viewChildren(BaseInputComponent);

    getTitle() {
        const entityTitle = this.translateService.instant(this.entityName.replace(/([A-Z])/g, ' $1').trim());
        return this.createMode ? `${this.translateService.instant('New')} ${entityTitle}` : `${this.getIdentifier()}`;
    }

    protected constructor(protected common: CommonService) {
        this.queryService = common.queryService;
        this.translateService = common.translateService;
        this.toastrNotificationService = common.toastrNotificationService;
        this.activatedRoute = common.activatedRoute;
        this.dialogService = common.dialogService;
        this.router = common.router;

        this.id = common.activatedRoute.snapshot.params.id;
        this.mode = common.activatedRoute.snapshot.data.mode;
        this.createMode = this.mode === ViewMode.create;
        this.viewMode = this.mode === ViewMode.view;
        this.editMode = !this.viewMode;
        this.user = this.common.userService.currentUserSubject.getValue();

        this.parentRoute = window.location.pathname
            .split('/')
            .slice(0, this.createMode ? -1 : -2)
            .join('/');

        this.activeTab = +this.router.routerState.snapshot.root.queryParams.tab || 0;
        this.actionBar = [
            {
                label: 'Edit Actions',
                items: [
                    {
                        label: 'Save',
                        icon: 'faSolidFloppyDisk',
                        variant: 'primary',
                        iconOnly: true,
                        isVisible: () => this.canEdit() && !this.viewMode,
                        onClick: () => this.saveChanges()
                    },
                    {
                        label: 'Edit',
                        icon: 'faSolidPenToSquare',
                        variant: 'primary',
                        iconOnly: true,
                        isVisible: () => this.canEdit() && this.viewMode,
                        isDisabled: () => this.isEditDisabled(),
                        onClick: () => this.navigateToEditMode()
                    },
                    {
                        label: 'Cancel Changes',
                        icon: 'faSolidXmark',
                        variant: 'info',
                        iconOnly: true,
                        isVisible: () => this.editMode,
                        onClick: () => this.cancelChanges()
                    }
                ]
            }
        ];
    }

    canDeactivate(): CanDeactivateReturn {
        if (!this.hasUnsavedChanges) return true;
        const deactivateSubject = new Subject<boolean>();
        this.dialogService
            .confirm({
                options: {
                    title: this.translateService.instant('Unsaved changes'),
                    message: this.translateService.instant(
                        'There are some unsaved changes. Do you want to leave and discard them?'
                    )
                }
            })
            .then((value) => {
                if (value) {
                    deactivateSubject.next(true);
                }
                deactivateSubject.next(false);
            });
        return deactivateSubject;
    }

    ngOnInit() {
        this.initialize();
    }

    async initialize() {
        if (this.createMode) await this.createEntity();
        else this.loadModel();
    }

    createEntity(): void | Promise<void> {
        if (!this.canCreateNew()) {
            this.navigateToList();
            this.toastrNotificationService.show({ type: 'error', message: 'User does not have create permissions' });
            return;
        }
        this.model = this.initialModel;
        this.arrayProperties.forEach((prop) => (this.model[prop] = []));
    }

    loadModel() {
        this.query$ = this.queryService.getQuery<TQuery>(
            this.entityName,
            { id: this.id, ...this.loadExtraParams },
            { injector: this.common.injector, refetchOnWindowFocus: this.viewMode }
        ).result$;
        this.query$
            .pipe(
                takeUntil(this.destroy$),
                tapSuccessResult<any>((data) => {
                    this.model = this.mapData(data);
                    this.modelLoaded();
                })
            )
            .subscribe();
    }

    getIdentifier() {
        return this.model.id;
    }

    mapData(data) {
        return data;
    }

    modelLoaded(): any {
        if (!this.model) return this.navigateToList();
        this.actionBar = [...this.actionBar]; // this triggers change detection in actionbar so that buttons can update (isHidden, isVisible, etc.)
        this.expandModelProperties();
        if (this.mode === ViewMode.edit && !this.canEdit()) {
            this.navigateToViewMode();
            this.toastrNotificationService.show({ type: 'error', message: 'User does not have edit permissions' });
            return;
        }
        this.title = this.getTitle();
        this.tabNavigate();
        if (this.editMode) this.hasUnsavedChanges = true;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    canCancel() {
        return !this.viewMode;
    }

    cancelChanges() {
        this.hasUnsavedChanges = false;
        if (this.createMode) this.navigateToList();
        else this.navigateToViewMode();
    }

    canEdit() {
        if (this.editPermission) return this.user.hasPermission(this.editPermission);
        return true;
    }

    isEditDisabled() {
        return false;
    }

    canCreateNew() {
        if (this.createPermission) return this.user?.hasPermission(this.createPermission);
        return true;
    }

    canClone() {
        return this.viewMode;
    }

    saveCommand(customData = {}): Promise<any> {
        return this.queryService.getCommandMutation().mutateAsync({
            command: `Save${this.entityName}`,
            data: { saveModel: this.model, action: this.mode, ...customData },
            invalidate: this.invalidateKey(this.entityName)
        });
    }

    /**
     * Overridable function for creating a custom key for invalidating cache
     */
    invalidateKey(entityName: string) {
        return `${entityName}s`;
    }

    beforeSave() {}

    async saveChanges(customData?: any) {
        if (!this.canSave()) {
            this.toastrNotificationService.show({
                type: 'error',
                message: 'Some required fields are empty or invalid'
            });
            return;
        }

        this.beforeSave();
        const result = await this.saveCommand(customData);
        if (result?.error) return;
        await this.afterSave(result);
    }

    async afterSave(model) {
        this.hasUnsavedChanges = false;
        await this.navigateToViewMode(model);
        this.toastrNotificationService.show({ type: 'success', message: 'Save successful' });
    }

    beforeClone() {}

    async cloneEntity() {
        this.beforeClone();
        this.model.id = -1;
        const result = await this.saveCommand({ action: ViewMode.create });
        if (result?.error) return;
        await this.afterClone(result);
    }

    async afterClone(model) {
        await this.navigateToEditMode(model);
        this.toastrNotificationService.show({ type: 'success', message: 'Clone successful' });
    }

    /**
     * Executes a command with the provided parameters.
     *
     * @param params - The parameters for the command execution.
     * @param params.command - The command to be executed.
     * @param params.data - Optional data to be sent with the command. Defaults to an empty object.
     * @param params.invalidate - Optional entity name to invalidate. Defaults to the entity name of the current instance.
     * @param params.customCallback - Optional custom callback function to be executed after the command. Defaults to the initialize method of the current instance.
     * @param params.showSpinner - Optional flag to show a spinner during the command execution.
     * @returns A promise that resolves with the result of the command execution.
     */
    async executeCommand(params: CommandParams): Promise<any> {
        const {
            command,
            data = {},
            invalidate = this.entityName,
            customCallback = () => this.initialize(),
            showSpinner
        } = params;

        return this.common.executeCommand({
            command,
            data: { id: this.model.id, ...data },
            customCallback,
            invalidate,
            showSpinner
        });
    }

    /**
     * Executes a workflow command with the provided parameters.
     *
     * @param params - The parameters required to execute the workflow command.
     * @param params.command - The command to be executed.
     * @param params.data - Additional data to be sent with the command.
     * @param params.customCallback - A custom callback function to be executed after the command. Defaults to initializing the view.
     * @param params.invalidate - The entity name to invalidate. Defaults to the current entity name.
     * @param params.baseName - The base name of the entity. Defaults to the current entity name.
     * @param params.showSpinner - A flag indicating whether to show a spinner during the command execution.
     * @returns A promise that resolves with the result of the command execution.
     */
    async executeWorkflowCommand(params: CommandParams): Promise<any> {
        const {
            command,
            data,
            customCallback = () => this.initialize(),
            invalidate = this.entityName,
            baseName = this.entityName,
            showSpinner
        } = params;

        return this.common.executeWorkflowCommand({
            command,
            data: { id: this.model.id, entityType: baseName, stateFromCode: this.model.statusId, ...data },
            customCallback,
            invalidate,
            showSpinner
        });
    }

    navigateToViewMode(model = this.model) {
        return this.router.navigate([`${this.parentRoute}/view/${model.id}`], this.getNavigationExtras());
    }

    navigateToEditMode(model = this.model) {
        return this.router.navigate([`${this.parentRoute}/edit/${model.id}`], this.getNavigationExtras());
    }

    navigateToList(): Promise<boolean> {
        return this.router.navigate([`${this.parentRoute}/list/`]);
    }

    expandModelProperties(model = this.model) {
        Object.getOwnPropertyNames(model).forEach((prop) => {
            // Set id properties for app-control binding
            if (_.isArray(model[prop]))
                model[prop].forEach((item) => {
                    if (!_.isString(item)) {
                        this.expandModelProperties(item);
                        item[`${_.camelCase(this.entityName)}Id`] = this.model.id;
                    }
                });
            else if (_.isObject(model[prop])) {
                model[`${prop}Id`] = model[prop]?.['id'];
                this.expandModelProperties(model[prop]);
            }
        });
    }

    getNavigationExtras(): NavigationExtras {
        return { queryParamsHandling: 'merge', onSameUrlNavigation: 'reload', replaceUrl: true };
    }

    canSave(
        appControls: readonly AppControlComponent[] = this.appControls(),
        inputs: readonly BaseInputComponent<any>[] = this.inputs()
    ): boolean {
        let res = true;
        res &&= !appControls.some((x) => x.error);
        inputs.forEach((input) => input.validate());
        res &&= !inputs.some((x) => x.error());
        return res;
    }

    // If we are using kendo-tabstip
    onTabSelect(select: SelectEvent) {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { tab: +select.index },
            ...this.getNavigationExtras()
        });
        this.activeTab = +select.index;
    }

    configureTabs() {}

    tabNavigate() {
        this.configureTabs();
        if (!this.tabs) return;
        const currentTab = this.tabs[this.activeTab];

        // If the selected tab is hidden, disabled or does not exist, find the first non-hidden tab
        if (this.isTabUnavailable(currentTab)) {
            this.activeTab = this.tabs.findIndex((x) => x.hidden !== true && x.disabled !== true);
        }

        setTimeout(() => {
            this.tabstrip?.selectTab(this.activeTab);
            this.onTabSelect({ index: this.activeTab } as SelectEvent);
        });
    }

    onSelectedKeysChange(keys: any[]) {
        this.selection = keys;
    }

    private isTabUnavailable(currentTab: Tab<any>) {
        return currentTab?.hidden || currentTab?.disabled || this.activeTab < 0 || this.activeTab >= this.tabs.length;
    }
}
