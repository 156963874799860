<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-fileselect
            class="w-full"
            [ngClass]="generateInputStyle()"
            [id]="id"
            [title]="title | translate"
            [restrictions]="restrictions"
            [showFileList]="showFileList"
            [multiple]="isMultiple"
            [disabled]="isDisabled"
            [required]="isRequired"
            [(ngModel)]="value"
            (select)="onFileSelected($event)"
            (inputFocus)="onFocusIn()"
            (inputBlur)="onFocusOut()">
            <kendo-fileselect-messages
                [select]="isMultiple ? ('Select files...' | translate) : ('Select file...' | translate)"
                [dropFilesHere]="'Drop files here' | translate" />
        </kendo-fileselect>
    } @else {
        <div class="pb-1 pt-1.5">
            {{ value() }}
        </div>
    }
</app-input-label>
