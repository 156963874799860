<form novalidate #form="ngForm">
    @if (title) {
        <div class="mb-1 ml-3 text-base text-muted">{{ title | translate }}</div>
    }
    <ng-template #template let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="template" (mouseover)="showTooltip($event)">
        <kendo-grid
            class="inline-editable rounded-lg border-0 bg-transparent shadow-md"
            [data]="localGridData"
            [skip]="localState.skip"
            [pageable]="pageable"
            [pageSize]="localState.take"
            [sortable]="sortable"
            [scrollable]="scrollable"
            [reorderable]="reorderable"
            [resizable]="resizable"
            [loading]="isBusy"
            [sort]="sort"
            [size]="gridSize"
            [rowClass]="rowClass"
            [navigable]="true"
            [selectable]="selectable"
            [kendoGridSelectBy]="selectBy"
            [(selectedKeys)]="selection"
            (dataStateChange)="onDataStateChanged($event)"
            (pageChange)="onPageChange($event)"
            (sortChange)="onSortChanged($event)"
            (selectedKeysChange)="selectedKeysChange.emit($event)"
            (cellClick)="cellClick.emit($event)"
            (cellClose)="cellClose.emit($event)"
            (columnReorder)="onColumnChange()"
            (columnResize)="onColumnChange()"
            (columnLockedChange)="onColumnChange()"
            (columnVisibilityChange)="onColumnChange()"
            (add)="onAdd($event, form)"
            (edit)="onEdit($event)"
            (cancel)="onCancel($event)"
            (save)="onSave($event)"
            (remove)="onRemove($event)"
            (excelExport)="onExcelExport($event)"
            (detailExpand)="onDetailExpanded($event)">
            @if (isSelectable) {
                <kendo-grid-checkbox-column [showSelectAll]="showSelectAll" [width]="40" />
            }
            @for (column of columnConfig; track column) {
                @if (column.isVisible) {
                    <kendo-grid-column
                        [title]="column.title | translate"
                        [field]="column.field"
                        [width]="column.width"
                        [class]="column.class"
                        [format]="column.format"
                        [style]="column.style"
                        [hidden]="column.isHidden">
                        @if (column.columnHeaderTooltip) {
                            <ng-template kendoGridHeaderTemplate>
                                <div
                                    class="flex w-full items-center justify-between gap-2"
                                    kendoTooltip
                                    title="{{ column.columnHeaderTooltip.text | translate }}">
                                    {{ column.title | translate }}
                                    <ng-container *ngIf="column.columnHeaderTooltip.showIcon">
                                        <ng-icon
                                            size="0.75rem"
                                            [class]="
                                                column.columnHeaderTooltip.iconClass ||
                                                'text-primary-foreground opacity-75'
                                            "
                                            [name]="column.columnHeaderTooltip.icon || 'faSolidCircleInfo'"></ng-icon>
                                    </ng-container>
                                </div>
                            </ng-template>
                        }
                        @if (column.editTemplate) {
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <app-grid-edit-template [column]="column" [row]="dataItem" />
                            </ng-template>
                        }
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            @if (column.isLink) {
                                <app-grid-cell-template
                                    [column]="column"
                                    [defaultViewMode]="defaultViewMode"
                                    [parentRoute]="parentRoute"
                                    [row]="dataItem" />
                            } @else {
                                @if (column.fieldTooltip) {
                                    <app-grid-cell-template
                                        kendoTooltip
                                        title="{{ column.fieldTooltip ? dataItem[column.fieldTooltip] : null }}"
                                        [column]="column"
                                        [row]="dataItem" />
                                } @else {
                                    <app-grid-cell-template
                                        [column]="column"
                                        [row]="dataItem"
                                        [ngClass]="column.conditionalClass ? column.conditionalClass(dataItem) : null">
                                        @if (column.indicator && column.indicator(dataItem)) {
                                            <span title="{{ column.indicator(dataItem)?.tooltip | translate }}">
                                                @if (column.indicator(dataItem)?.route) {
                                                    <app-link
                                                        [route]="[
                                                            column.indicator(dataItem)?.route?.path,
                                                            dataItem[column.indicator(dataItem)?.route?.field]
                                                        ]"
                                                        [queryParams]="column.indicator(dataItem)?.route?.queryParams">
                                                        <ng-icon
                                                            [ngClass]="column.indicator(dataItem)?.class"
                                                            [name]="column.indicator(dataItem)?.icon" />
                                                    </app-link>
                                                } @else {
                                                    <ng-icon
                                                        [ngClass]="column.indicator(dataItem)?.class"
                                                        [name]="column.indicator(dataItem)?.icon" />
                                                }
                                            </span>
                                        }
                                    </app-grid-cell-template>
                                }
                            }
                        </ng-template>
                    </kendo-grid-column>
                }
            }
            @if (editMode) {
                <kendo-grid-command-column [width]="100">
                    <ng-template kendoGridCellTemplate let-isNew="isNew" let-row>
                        @if (canEditRow(row)) {
                            <div class="flex h-full flex-row items-center gap-2">
                                @if (canClone) {
                                    <app-button variant="primary" icon="faSolidClone" (clicked)="onClone(row, form)" />
                                }
                                @if (canEdit) {
                                    <button
                                        kendoGridEditCommand
                                        kendoTooltip
                                        title="{{ 'Edit' | translate }}"
                                        [primary]="true">
                                        <ng-icon name="faSolidPenToSquare" />
                                    </button>
                                    <button kendoGridSaveCommand kendoTooltip title="{{ 'Confirm' | translate }}">
                                        @if (isNew !== undefined) {
                                            <ng-icon name="faSolidCheck" />
                                        }
                                    </button>
                                }
                                @if (canEdit && canDelete) {
                                    <button kendoGridRemoveCommand kendoTooltip title="{{ 'Delete' | translate }}">
                                        <ng-icon name="faSolidTrash" />
                                    </button>
                                }
                                <button kendoGridCancelCommand kendoTooltip title="{{ 'Cancel' | translate }}">
                                    <ng-icon name="faSolidBan" />
                                </button>
                            </div>
                        }
                    </ng-template>
                </kendo-grid-command-column>
            }
            <ng-template kendoGridToolbarTemplate>
                @if (editMode && canEdit && canAddNew && !isEditing) {
                    <button
                        class="btn btn-primary btn-md border-0"
                        kendoTooltip
                        title="{{ 'Add' | translate }}"
                        kendoGridAddCommand>
                        <ng-icon name="faSolidPlus" />
                    </button>
                }
                @for (section of buttons; track $index) {
                    @if (['Actions', 'Export'].includes(section.label)) {
                        @for (button of section?.items; track $index) {
                            @if (resolveProperty(button?.isVisible)) {
                                <app-button
                                    class="pr-2"
                                    [variant]="button?.variant"
                                    [disabled]="resolveProperty(button?.isDisabled)"
                                    [tooltip]="button?.label.toString() | translate"
                                    [icon]="button?.icon"
                                    (clicked)="button?.onClick()">
                                    @if (!resolveProperty(button?.iconOnly)) {
                                        <span class="leading-3">
                                            {{ button?.label.toString() | translate }}
                                        </span>
                                    }
                                </app-button>
                            }
                        }
                    }
                }
                <kendo-grid-spacer />
                <ng-content select="[toolbarCenter]" />
                <kendo-grid-spacer />
                @if (canExport) {
                    <app-button
                        class="mr-1"
                        variant="excel"
                        icon="faSolidFileExcel"
                        tooltip="{{ 'Export to Excel' | translate }}"
                        (clicked)="exportToExcel()" />
                }
            </ng-template>
            @if (canExport) {
                <kendo-grid-excel fileName="Export.xlsx" />
            }
            <kendo-grid-messages
                pagerItems="{{ 'items' | translate }}"
                pagerItemsPerPage="{{ 'items per page' | translate }}"
                noRecords="{{ 'No records available' | translate }}"
                pagerOf="{{ 'of' | translate }}" />
        </kendo-grid>
    </div>
</form>
