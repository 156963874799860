<kendo-dialog-titlebar class="mb-2 bg-background px-4 py-6 font-bold" translate (close)="close()">
    {{ 'New User' | translate }}
</kendo-dialog-titlebar>
<app-stepper
    stepType="full"
    [linear]="false"
    [steps]="steps"
    [validateStep]="validateStep"
    [(currentStep)]="currentStep" />
@if (currentStep() === 0) {
    <div class="m-4 flex flex-col gap-4">
        <div class="l:grid-cols-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4" setEditMode [isEditMode]="true">
            <app-text-input
                label="Full Name"
                id="fullName"
                [isRequired]="true"
                [(value)]="model.fullName"
                (inputBlur)="onFullNameBlur()" />
            <form class="col-span-1">
                <app-text-input label="Username" id="userName" [isRequired]="true" [(value)]="model.userName" />
            </form>
            <form class="col-span-1">
                <app-text-input
                    label="Password"
                    id="password"
                    autocomplete="new-password"
                    [password]="true"
                    [isRequired]="true"
                    [(value)]="model.password" />
            </form>
            <app-codelist-input
                label="Organization"
                id="organizationId"
                codelist="Organization"
                [isRequired]="true"
                [(value)]="model.organizationId" />
            <form class="col-span-1">
                <app-text-input label="E-mail" id="email" pattern="[\w\d\.\-\_\@]" [(value)]="model.email" />
            </form>
            <app-text-input label="Phone Number" id="phoneNumber" pattern="^[0-9]*$" [(value)]="model.phoneNumber" />
            <app-codelist-input
                label="Language"
                id="languageId"
                codelist="Language"
                [isRequired]="true"
                [(value)]="model.languageId" />
        </div>
    </div>
} @else if (currentStep() === 1) {
    <app-editable-grid
        [data]="model.attachments"
        [filterable]="false"
        [editMode]="true"
        [cols]="attachmentColumns"
        [validateRow]="validateAttachmentRow"
        [initialData]="{ active: true }" />
}
<kendo-dialog-actions class="flex justify-between px-4">
    <div class="flex-1">
        <app-button variant="secondary" icon="faSolidXmark" (click)="close()">
            {{ 'Cancel' | translate }}
        </app-button>
    </div>
    <div class="flex flex-1 justify-center">
        @if (currentStep() !== 0) {
            <app-button variant="secondary" icon="faSolidAnglesLeft" (click)="stepper().onStepChange(-1)">
                {{ 'Previous' | translate }}
            </app-button>
        }
        @if (currentStep() < steps.length - 1) {
            <app-button
                variant="secondary"
                icon="faSolidAnglesRight"
                iconPosition="end"
                (click)="stepper().onStepChange(1)">
                {{ 'Next' | translate }}
            </app-button>
        }
    </div>
    <div class="flex flex-1 justify-end">
        <app-button variant="secondary" icon="faSolidCheck" iconPosition="end" (click)="confirm()">
            {{ 'Create' | translate }}
        </app-button>
    </div>
</kendo-dialog-actions>
