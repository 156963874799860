@if (settingKey.toLowerCase() === 'dateformat') {
    <span class="mb-1 text-xs text-gray-500">Example: {{ dateFormatExample }}</span>
    <app-select-input
        id="settingValue"
        [options]="dateFormats()"
        [isEditMode]="true"
        [(value)]="settingValue"
        (valueChange)="updateDateFormatExample($event)" />
}
@if (timeFormat) {
    <span class="mb-1 text-xs text-gray-500">Example: {{ timeFormatExample }}</span>
    <app-select-input
        id="settingValue"
        [options]="timeFormats()"
        [isEditMode]="true"
        [(value)]="settingValue"
        (valueChange)="updateTimeFormatExample($event)" />
}
