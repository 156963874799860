import { Injectable } from '@angular/core';
import { EditEvent } from '@progress/kendo-angular-grid';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class GridEditService {
    editedRowIndex: number;
    editedRow: any;

    addHandler({ sender }, form, initialData: object): object {
        form.reset();
        this.closeEditor(sender);
        const newDataItem = structuredClone(initialData);
        sender.data.data.unshift(newDataItem);
        sender.editRow(0);
        sender.expandRow(0);
        return newDataItem;
    }

    editHandler({ sender, rowIndex, dataItem }: EditEvent) {
        this.closeEditor(sender);
        this.editedRowIndex = rowIndex;
        this.editedRow = _.clone(dataItem);
        sender.editRow(rowIndex);
        sender.expandRow(rowIndex);
    }

    saveHandler({ sender, rowIndex, dataItem }) {
        const [lastItem] = sender.data.data
            .filter((x) => (Object.prototype.hasOwnProperty.call(x, 'active') ? x.active : true))
            .sort((a, b) => b.id - a.id)
            .slice(-1);
        dataItem.id = lastItem?.id ? lastItem.id - 1 : -1;
        dataItem.isNew = false;
        this.closeEditor(sender, rowIndex);
    }

    closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        grid.collapseRow(rowIndex);
        this.editedRowIndex = this.editedRow = undefined;
    }

    cancelHandler({ sender, rowIndex, dataItem }) {
        if (!dataItem) return; // Used for when cancelling selection of files for file input
        if (dataItem.isNew) sender.data.data.splice(rowIndex, 1);
        else sender.data.data[rowIndex] = this.editedRow;
        this.closeEditor(sender, rowIndex);
    }

    removeHandler({ sender, rowIndex }) {
        sender.data.data.splice(rowIndex, 1);
    }

    cloneHandler(grid, form, item): object {
        form.reset();
        const clone = _.clone(item);
        clone.unitCode = null;
        delete clone.id;
        grid.data.data.unshift(clone);
        grid.editRow(0);
        grid.expandRow(0);
        return clone as object;
    }
}
