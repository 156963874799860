import { Routes } from '@angular/router';
import { UnauthorizedComponent } from '@common/components/unauthorized.component';
import { AppSettings, checkFeatureAvailabilityGuard } from '@common/guards/check-feature-availability.guard';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { UserAccountModule } from './user-account/user-account.module';
import { UserSettingsModule } from './user-settings/user-settings.module';

export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '401', component: UnauthorizedComponent },
    { path: 'logout', component: LogoutComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            {
                path: 'vessel',
                loadChildren: () => import('./vessel/vessel.module').then((m) => m.VesselModule),
                data: { title: 'Vessels' }
            },
            {
                path: 'vessel-visit',
                loadChildren: () => import('./vessel-visit/vessel-visit.module').then((m) => m.VesselVisitModule),
                data: { title: 'Vessel Visits' }
            },
            {
                path: 'berth-request',
                loadChildren: () => import('./berth-request/berth-request.module').then((m) => m.BerthRequestModule),
                data: { title: 'Berth Requests' }
            },
            {
                path: 'service-request',
                loadChildren: () =>
                    import('./service-request/service-request.module').then((m) => m.ServiceRequestModule),
                data: { title: 'Service Requests' }
            },
            {
                path: 'container-visit',
                loadChildren: () =>
                    import('./container-visit/container-visit.module').then((m) => m.ContainerVisitModule),
                data: { title: 'Container Visits' }
            },
            {
                path: 'delivery-order',
                loadChildren: () => import('./delivery-order/delivery-order.module').then((m) => m.DeliveryOrderModule),
                data: { title: 'Delivery Orders' }
            },
            {
                path: 'container-stripping',
                loadChildren: () =>
                    import('./container-stripping/container-stripping.module').then((m) => m.ContainerStrippingModule),
                data: { title: 'Container Strippings' }
            },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then((m) => m.AdministrationModule),
                data: { title: 'Administration' }
            },
            { path: 'home', component: HomeComponent },
            {
                path: 'user-account',
                loadChildren: () => UserAccountModule,
                data: { title: 'Account' }
            },
            {
                path: 'notice-board',
                loadChildren: () => import('./notice-board/notice-board.module').then((m) => m.NoticeBoardModule),
                canActivate: [checkFeatureAvailabilityGuard],
                data: { title: 'Notifications', check: AppSettings.NoticeBoard }
            },
            {
                path: 'user-settings',
                loadChildren: () => UserSettingsModule,
                data: { title: 'User Settings' }
            },
            {
                path: 'faq',
                loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
                data: { title: 'FAQ' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
