import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, signal, Signal } from '@angular/core';
import { SettingKeyCodes } from '@common/known-types/setting-key.codes';
import { UserSettingCategoryCodes } from '@common/known-types/user-setting-category.codes';
import { environment } from '@environments/environment.base';

@Component({
    selector: 'app-date-format-selector',
    templateUrl: './date-format-selector.component.html',
    providers: [DatePipe]
})
export class DateFormatSelectorComponent implements OnChanges {
    @Input() settingKey: string;
    @Input() settingValue: string;
    @Input() timeFormat: boolean = false;

    @Output() settingValueChanged: EventEmitter<string> = new EventEmitter<string>();

    dateFormats: Signal<string[]> = signal([
        'dd/MM/yy',
        'dd/MM/yyyy',
        'yy/MM/dd',
        'yyyy/MM/dd',
        'MM/dd/yy',
        'M/d/yy',
        'd/M/yy',
        'MM.dd.yy',
        'dd.MM.yy',
        'yy.MM.dd',
        'M.d.yy',
        'd.M.yy',
        'EEEE, MMMM d, y',
        'MMMM d, y',
        'MMM d, y',
        'EEE, MMM d, y',
        'EEEE d MMMM y'
    ]);
    dateFormatExample: string;

    timeFormats: Signal<string[]> = signal([
        'h:mm a',
        'H:mm',
        'HH:mm:ss',
        'h:mm:ss a',
        'H:mm:ss',
        'h:mm:ss.SSS a',
        'H:mm:ss.SSS'
    ]);
    timeFormatExample: string;

    constructor(private datePipe: DatePipe) {}

    ngOnChanges() {
        if ([SettingKeyCodes.DateFormat, UserSettingCategoryCodes.DateFormat].includes(this.settingKey))
            this.updateDateFormatExample();
        if ([SettingKeyCodes.TimeFormat, UserSettingCategoryCodes.TimeFormat].includes(this.settingKey))
            this.updateTimeFormatExample();
    }

    updateDateFormatExample(value: string = null) {
        const date = new Date();
        this.settingValue = value ? value : this.settingValue || environment.settings.appControl.dateFormat;
        this.dateFormatExample = this.datePipe.transform(date, this.settingValue);
        this.settingValueChanged.emit(this.settingValue);
    }

    updateTimeFormatExample(value: string = null) {
        const date = new Date();
        this.settingValue = value ? value : this.settingValue || environment.settings.appControl.timeFormat;
        this.timeFormatExample = this.datePipe.transform(date, this.settingValue);
        this.settingValueChanged.emit(this.settingValue);
    }
}
