import { Component, EventEmitter, HostBinding, inject, Input, model, OnInit, Output, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { twJoin } from 'tailwind-merge';
import { BaseInput, InputType } from '../input.type';

/**
 * Base class for all input components.
 * @template T The type of the input value.
 * @param {InputType} type -  The type of the input.
 * @param {string} id - The id of the input.
 * @param {string} title - The title of the input.
 * @param {string} label - The label of the input.
 * @param {LabelPosition} labelPosition - The position of the label.
 * @param {string} class - The class of the input.
 * @param {string} placeholder - The placeholder of the input.
 * @param {boolean} isDisabled - Whether the input is disabled.
 * @param {boolean} isRequired - Whether the input is required.
 * @param {boolean} isEditMode - If not in edit mode, only show the value.
 * @param {Function} validateFn - The validation function that is called on value change.
 * @param {EventEmitter<any>} valueChange - The value change event emitter.
 */
@Component({ template: '' })
export abstract class BaseInputComponent<T> implements OnInit {
    value = model.required<T>();
    translationService = inject(TranslateService);

    @Input() type: InputType;
    @Input({ required: true }) id: BaseInput['id'];
    @Input() title: BaseInput['title'] = '';
    @Input() label: BaseInput['label'];
    @Input() labelPosition: BaseInput['labelPosition'] = 'top';
    @Input() class: BaseInput['class'];
    @Input() placeholder: BaseInput['placeholder'];
    @Input() initialValue: BaseInput['initialValue'];
    @Input() isDisabled: BaseInput['isDisabled'] = false;
    @Input() isRequired: BaseInput['isRequired'] = false;
    @Input() isEditMode: BaseInput['isEditMode'];
    @Input() validateFn: BaseInput['validateFn'];
    @Input() customRoute: BaseInput['customRoute'];
    @Output() valueChange = new EventEmitter<any>();
    @Output() inputBlur = new EventEmitter<void>();

    @HostBinding('attr.class')
    get btnClass() {
        return '';
    }

    error = signal<string[]>(null);
    touched = signal<boolean>(false);
    dirty = signal<boolean>(false);
    focused = signal<boolean>(false);

    ngOnInit() {
        // Execute after content has been checked
        if (this.initialValue) this.value.set(this.initialValue);
    }

    onFocusIn() {
        this.touched.set(true);
        this.focused.set(true);
    }

    onFocusOut() {
        this.focused.set(false);
        this.inputBlur.emit();
    }

    onValueChange(value: T) {
        this.validate();
        this.dirty.set(true);
        this.valueChange.emit(value);
    }

    validate(value?: T) {
        const error: string[] = [];
        if (!value) value = this.value();

        if (this.validateFn) {
            const validateResult = this.validateFn(value);
            if (validateResult) {
                error.push(validateResult);
            }
        }

        if (this.isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
            error.push(this.translationService.instant('This field is required.'));
        }

        this.error.set(error.length > 0 ? error : null);
    }

    generateInputStyle = () =>
        twJoin(
            'bg-input text-input-foreground',
            this.isDisabled && ['cursor-not-allowed', 'brightness-75'],
            this.error()?.length > 0 && '!ring-1 !ring-danger',
            this.class
        );
}
