import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists, CodelistsService } from '@administration/codelists/codelists.service';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'codelist'
})
export class CodelistPipe implements PipeTransform {
    private codelistsService = inject(CodelistsService);

    transform(id: string | number, codelistName: Codelists | FakedCodelists, prefixItemWithId: boolean = false) {
        return this.codelistsService.getCodelistDisplayText(id, codelistName, prefixItemWithId);
    }
}
