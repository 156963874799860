import { Component, Input, OnInit } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { formatCharacterCount } from '../input.helpers';
import { TextareaInputConfig } from '../input.type';

/**
 * Textarea input component.
 * @param maxLength - The maximum length of the text.
 * @param rows - The number of rows.
 */
@Component({
    selector: 'app-textarea-input',
    templateUrl: './textarea-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: TextareaInputComponent }]
})
export class TextareaInputComponent extends BaseInputComponent<string> implements OnInit {
    @Input() maxLength: TextareaInputConfig['maxLength'] = 255;
    @Input() rows: TextareaInputConfig['rows'] = 3;
    characterCount: string;

    override ngOnInit(): void {
        super.ngOnInit();
        if (!this.value()) {
            // This is to avoid displaying 'undefined' when the value passed in is undefined eg.: model.username, where username is undefined.
            this.value.set(null);
        }
        this.characterCount = formatCharacterCount(this.maxLength, this.value());
    }

    override onValueChange(value: string): void {
        super.onValueChange(value);
        this.characterCount = formatCharacterCount(this.maxLength, value);
    }
}
