import { Component, Input, computed, input, signal } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseInputComponent } from '../base-input/base-input.component';
import { SelectInputConfig } from '../input.type';

/**
 * Select input component.
 * @param options - List of options for the select input
 * @param isMultiple - Boolean value to determine if the select input is multiple
 * @param isFilterable - Boolean value to determine if the select input is filterable
 * @param isClearable - Boolean value to determine if the select input is clearable
 * @param textField - Key for the text field in the options
 * @param valueField - Key for the value field in the options
 * @param popupSettings - Object for the popup settings
 * @param defaultItem - Item selected by default
 * @param customFilter - Custom filter function applied when filtering options
 * @param valuePrimitive - Boolean value to determine if the value is primitive
 */
@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: SelectInputComponent }]
})
export class SelectInputComponent extends BaseInputComponent<string | string[] | number | number[]> {
    options = input<SelectInputConfig['options']>();
    @Input() isMultiple: SelectInputConfig['isMultiple'];
    @Input() isFilterable: SelectInputConfig['isFilterable'];
    @Input() isClearable: SelectInputConfig['isClearable'];
    @Input() textField: SelectInputConfig['textField'] = 'label';
    @Input() valueField: SelectInputConfig['valueField'] = 'value';
    @Input() popupSettings: SelectInputConfig['popupSettings'] = environment.settings.appControl.dropdown.popupSettings;
    @Input() defaultItem: SelectInputConfig['defaultItem'];
    @Input() customFilter: SelectInputConfig['customFilter'];
    @Input() valuePrimitive: SelectInputConfig['valuePrimitive'] = true;

    filterTerm = signal<string>('');
    formatedSelectedOptions = computed(() => this.formatSelectedOptions());
    filteredOptions = computed<SelectInputConfig['options']>(() => this.applyFilter(this.filterTerm()));

    private applyFilter(searchTerm: string) {
        const normalizedTerm = searchTerm.toLowerCase();

        return this.customFilter
            ? this.options()?.filter((x: any) => this.customFilter(x, normalizedTerm))
            : this.options()?.filter((x: any) => x[this.textField].toLowerCase().includes(normalizedTerm));
    }

    /**
     * Filters array of options to get a string of option labels for view mode
     * @returns {string} string of selected options
     */
    formatSelectedOptions(): string {
        if (!this.options()) return null;

        if (this.isMultiple) {
            const selectedItems = this.value();
            if (!Array.isArray(selectedItems) || selectedItems.length === 0) {
                return null;
            }

            return selectedItems
                .map((val) => this.options()?.find((option) => option.value === val)?.label)
                .filter(Boolean)
                .join(', ');
        } else {
            const selectedItem = this.options()?.find((item) => item.value === this.value());
            return selectedItem?.label ?? null;
        }
    }
}
